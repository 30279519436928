<template>
  <div class="flex mb-24 stats__content top__content">
    <div class="stats-item">
      <div class="count">
        <span>{{ englishToNepaliNumber(total) }}</span>
        कागजातहरू
      </div>
    </div>
    <div class="stats-item">
      <div class="count">
        <span
          >{{
            this.statistics["Laws / Bylaws / Rules / Regulations / Directives"]
              ? englishToNepaliNumber(
                  this.statistics[
                    "Laws / Bylaws / Rules / Regulations / Directives"
                  ]
                )
              : englishToNepaliNumber(0)
          }}
        </span>
        कानुन/विनियम। नियम/नियमावली/निर्देशिका
      </div>
    </div>
    <div class="stats-item">
      <div class="count">
        <span>
          {{
            this.statistics["Research Reports"]
              ? englishToNepaliNumber(this.statistics["Research Reports"])
              : englishToNepaliNumber(0)
          }}
        </span>
        अनुसन्धान प्रतिवेदन
      </div>
    </div>
    <div class="stats-item">
      <div class="count">
        <span>
          {{
            this.statistics["Surveys"]
              ? englishToNepaliNumber(this.statistics["Surveys"])
              : englishToNepaliNumber(0)
          }}
        </span>
        सर्वेक्षणहरु
      </div>
    </div>
    <div class="stats-item">
      <div class="count">
        <span>
          {{
            this.statistics["Government Annual Progress Report"]
              ? englishToNepaliNumber(
                  this.statistics["Government Annual Progress Report"]
                )
              : englishToNepaliNumber(0)
          }}
        </span>
        सरकारकाे वार्षिक प्रगति प्रतिवेदन
      </div>
    </div>
  </div>
</template>
<script>
import { englishToNepaliNumber } from "nepali-number";
export default {
  data() {
    return {
      statistics: {},
      englishToNepaliNumber,
    };
  },
  props: {
    stats: { type: Array, required: false },
  },
  watch: {
    stats: {
      handler(val) {
        this.statistics = val.reduce((acc, ele) => {
          acc[ele.name] = ele.count;
          return acc;
        }, {});
      },
    },
  },
  computed: {
    total() {
      return this.stats.reduce((acc, ele) => {
        return acc + ele.count;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.stats__content {
  padding-left: 120px;
  padding-right: 120px;
}
</style>
